import * as amplitude from '@amplitude/analytics-browser'
import { useEffect } from 'react'

const API_KEY = process.env.GATSBY_AMPLITUDE_API_KEY

const amplitudeInit = () => {
  amplitude.init(API_KEY, {
    defaultTracking: false
  })
}

export const useInitAmplitude = () => useEffect(() => amplitudeInit(), [])
